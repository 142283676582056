import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// pages/
import ArchitecturalStudio from 'pages/architecturalStudio';
import ArtistRetreat from 'pages/artistRetreat';
import Barn from 'pages/barn';
import BusStop from 'pages/busStop';
import CabinRemodel from 'pages/cabinRemodel';
import DesignCenter from 'pages/designCenter';
import Garage from 'pages/garage';
import Park from 'pages/park';
import Home from 'pages/home';
import Layout from 'pages/layout';
import MadisonArchive from 'pages/madisonArchive';
import MadisonCollegeGateway from 'pages/madisonCollegeGateway';
import NotFound from 'pages/notFound';
import Office from 'pages/office';
import Playground from 'pages/playground';
import XboxMuseum from 'pages/xboxMuseum';


function App() {

  let pages = require.context('pages/', false, /^pages(.*?)(\.js)$/);
  let routes = [];
  pages.keys().forEach(filename => {
    let prettyFilename = filename.split('/')[1].split('.')[0];
    let jsx =
      routes.push(
        <Route path="architectural-studio" element={<ArchitecturalStudio />} />
      );
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="architectural-studio" element={<ArchitecturalStudio />} />
          <Route path="artist-retreat" element={<ArtistRetreat />} />
          <Route path="barn" element={<Barn />} />
          <Route path="bus-stop" element={<BusStop />} />
          <Route path="cabin-remodel" element={<CabinRemodel />} />
          <Route path="design-center" element={<DesignCenter />} />
          <Route path="garage" element={<Garage />} />
          <Route path="park" element={<Park />} />
          <Route path="madison-archive" element={<MadisonArchive />} />
          <Route path="madison-college-gateway" element={<MadisonCollegeGateway />} />
          <Route path="office" element={<Office />} />
          <Route path="playground" element={<Playground />} />
          <Route path="xbox-museum" element={<XboxMuseum />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
