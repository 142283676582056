import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function DesignCenter() {
    return (
        <>
            <Header pageName="Design Center" imagePaths={['images/autocad.svg', 'images/sketchup.svg', 'images/lumion.svg',]} summary="One of the most challenging aspects of this project was developing a design that not only met my professor's expectations but also offered both aesthetic appeal and functionality. The project spanned a period of approximately one month, during which time I worked diligently to refine the design. Personally, I found the creation of the oriental stairs located adjacent to the vestibule entrance to be the most rewarding aspect of the project." />
            <div className="content-container">
                <Model path="models/design-center.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/design-center/0.jpg' },
                    { imagePath: 'images/design-center/1.jpg' },
                    { imagePath: 'images/design-center/2.jpg' },
                    { imagePath: 'images/design-center/3.jpg' },
                    { imagePath: 'images/design-center/4.jpg' },
                    { imagePath: 'images/design-center/5.jpg' },
                    { imagePath: 'images/design-center/6.jpg' },
                    { imagePath: 'images/design-center/7.jpg' },
                    { imagePath: 'images/design-center/8.jpg' },
                    { imagePath: 'images/design-center/9.jpg' },
                    { imagePath: 'images/design-center/10.jpg' },
                    { imagePath: 'images/design-center/11.jpg' }
                ]} />

            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default DesignCenter;