import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function ArtistRetreat() {
    return (
        <>
            <Header pageName="Artist Retreat" imagePaths={['images/revit.svg', 'images/lumion.svg', 'images/blender.svg']} summary="In this project, I was given a two-week deadline to develop a solution inspired by the poem 'The Prairie-Grass Dividing', within the constraints of a 500 sqft space. Although the project presented a significant challenge, it was a successful endeavor, and I was able to deliver a well-crafted solution." />
            <div className="content-container">
                <Model path="models/artist-retreat.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/artist-retreat/0.jpg' },
                    { imagePath: 'images/artist-retreat/1.jpg' },
                    { imagePath: 'images/artist-retreat/2.jpg' },
                    { imagePath: 'images/artist-retreat/3.jpg' },
                    { imagePath: 'images/artist-retreat/4.jpg' },
                    { imagePath: 'images/artist-retreat/5.jpg' },
                    { imagePath: 'images/artist-retreat/6.jpg' },
                    { imagePath: 'images/artist-retreat/7.jpg' },
                    { imagePath: 'images/artist-retreat/8.jpg' }
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default ArtistRetreat;