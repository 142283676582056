import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function CabinRemodel() {
    return (
        <>
            <Header pageName="Cabin Remodel" imagePaths={['images/revit.svg', 'images/blender.svg']} summary="In this project, the existing parts of the cabin were provided, while the new and demolishing parts were left for me to complete independently. The primary objective of the project was to gain a clear understanding of how to effectively demonstrate existing, demolition, and new components in a professional manner, adhering to industry standards." />
            <div className="content-container">
                <Model path="models/cabin-remodel.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/cabin-remodel/0.jpg' },
                    { imagePath: 'images/cabin-remodel/1.jpg' },
                    { imagePath: 'images/cabin-remodel/2.jpg' }
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default CabinRemodel;