import React, { useEffect, useRef } from "react"
import 'components/Frame/frame.css'

function resizeImage(frameRef, imageRef, reverse = false) {
    let frameAspect = frameRef.current.getBoundingClientRect().width / frameRef.current.getBoundingClientRect().height;
    let imageAspect = imageRef.current.getBoundingClientRect().width / imageRef.current.getBoundingClientRect().height;

    if (!reverse) {
        if (frameAspect < imageAspect) {
            frameRef.current.classList.remove('frame--landscape');
            frameRef.current.classList.add('frame--portrait');
        }
        if (frameAspect > imageAspect) {
            frameRef.current.classList.remove('frame--portrait');
            frameRef.current.classList.add('frame--landscape');
        }
    }

    if (reverse) {
        if (frameAspect > imageAspect) {
            frameRef.current.classList.remove('frame--landscape');
            frameRef.current.classList.add('frame--portrait');
        }
        if (frameAspect < imageAspect) {
            frameRef.current.classList.remove('frame--portrait');
            frameRef.current.classList.add('frame--landscape');
        }
    }
}

function Frame(props) {

    const frameRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        window.addEventListener('resize', () => {
            resizeImage(frameRef, imageRef);
        });
        imageRef.current.addEventListener('load', () => {
            resizeImage(frameRef, imageRef);
        });
    }, []);

    return (
        <div className="frame frame--landscape" ref={frameRef}>
            <img src={props.imagePath} ref={imageRef} />
        </div>
    );
}

export default Frame;