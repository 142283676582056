import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function BusStop() {
    return (
        <>
            <Header pageName="Bus Stop" imagePaths={['images/sketchup.svg', 'images/autocad.svg', 'images/lumion.svg', 'images/blender.svg']} summary="This project was completed for my Studio 2 course at Madison College and provided a refreshing creative opportunity that differed from more challenging projects I have completed in the past. Throughout the project, I gained a deeper understanding of the importance and meaning of schematic designs, including site plans, floor plans, elevations, and building sections." />
            <div className="content-container">
                <Model path="models/bus-stop.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/bus-stop/0.jpg' },
                    { imagePath: 'images/bus-stop/1.jpg' },
                    { imagePath: 'images/bus-stop/2.jpg' },
                    { imagePath: 'images/bus-stop/3.jpg' },
                    { imagePath: 'images/bus-stop/4.jpg' },
                    { imagePath: 'images/bus-stop/5.jpg' },
                    { imagePath: 'images/bus-stop/6.jpg' },
                    { imagePath: 'images/bus-stop/7.jpg' },
                    { imagePath: 'images/bus-stop/8.jpg' },
                    { imagePath: 'images/bus-stop/9.jpg' },
                    { imagePath: 'images/bus-stop/10.jpg' },
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default BusStop;