import React, { useEffect, useRef } from "react";
import 'components/Carousel/carousel.css'

function resizeImage(slideRef, imageRef) {
    let slideAspect = slideRef.current.getBoundingClientRect().width / slideRef.current.getBoundingClientRect().height;
    let imageAspect = imageRef.current.getBoundingClientRect().width / imageRef.current.getBoundingClientRect().height;

    if (slideAspect > imageAspect) {
        slideRef.current.classList.remove('frame--landscape');
        slideRef.current.classList.add('frame--portrait');
    }

    if (slideAspect < imageAspect) {
        slideRef.current.classList.remove('frame--portrait');
        slideRef.current.classList.add('frame--landscape');
    }
}

function CarouselSlide(props) {

    const slideRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        window.addEventListener('resize', () => {
            resizeImage(slideRef, imageRef);
        });

        imageRef.current.addEventListener('load', () => {
            resizeImage(slideRef, imageRef);
        });
    }, []);

    return (
        <div id={props.id} className="carousel__slide" key={Math.random()} ref={slideRef}>
            <img src={props.imagePath} alt="" ref={imageRef} />
        </div>
    );
}


export default function Carousel(props) {
    const slides = props.imagePaths.map((imagePath, index) => {
        return (
            <CarouselSlide id={`image${index}`} imagePath={imagePath} key={Math.random()} />
        );
    });

    return (
        <div className="cover">
            <button className="exit-button" type="button" onClick={() => { props.setCarouselOpen(false) }}>
                <img src="images/exit.svg" alt="" />
            </button>
            <div className="carousel">
                {slides}
            </div>
        </div>
    );
}