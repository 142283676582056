import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function Garage() {
    return (
        <>
            <Header pageName="Garage" imagePaths={['images/autocad.svg', 'images/sketchup.svg', 'images/lumion.svg', 'images/blender.svg']} summary="This project stands apart from others, as it is a constructed garage located in my parent's backyard. The initial planning stages began with a simple site plan, which was gradually enhanced to include a floor plan and 3D model as the construction progressed. This process served to facilitate decision-making and provide a comprehensive understanding of the final product." />
            <div className="content-container">
                <Model path="models/garage.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/garage/0.jpg', },
                    { imagePath: 'images/garage/1.jpg', },
                    { imagePath: 'images/garage/2.jpg', },
                    { imagePath: 'images/garage/3.jpg', },
                    { imagePath: 'images/garage/4.jpg', },
                    { imagePath: 'images/garage/5.jpg', },
                    { imagePath: 'images/garage/6.jpg', },
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default Garage;