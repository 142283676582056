import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from "components/Mosaic/mosaic";
import ScrollButton from 'components/ScrollButton/scrollButton'

function Barn() {
    return (
        <>
            <Header pageName="Barn" imagePaths={['images/sketchup.svg', 'images/lumion.svg', 'images/blender.svg']} summary="During my Intro to Architecture course at Madison College, I gained valuable experience working with SketchUp and Lumion. This barn project represents one of the first assignments completed for this class. The process of constructing the individual stalls for the animals was quite time-consuming. However, I recognize that attention to detail is an essential aspect of architecture and I am proud to have achieved this quality in the final result." />
            <div className="content-container">
                <Model path="models/barn.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/barn/0.jpg' },
                    { imagePath: 'images/barn/1.jpg' },
                    { imagePath: 'images/barn/2.jpg' },
                    { imagePath: 'images/barn/3.jpg' },
                    { imagePath: 'images/barn/4.jpg' },
                    { imagePath: 'images/barn/5.jpg' },
                    { imagePath: 'images/barn/6.jpg' },
                    { imagePath: 'images/barn/7.jpg' },
                    { imagePath: 'images/barn/8.jpg' }
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default Barn;