import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function Office() {
    return (
        <>
            <Header pageName="Office" imagePaths={['images/revit.svg', 'images/lumion.svg', 'images/blender.svg']} summary="The primary objective of this project was to produce a preliminary design for an office building and gain knowledge regarding the placement and configuration of its structural components. Additionally, the project aimed to provide instruction on creating a comprehensive Construction Document (CD) set. The overall focus of the project was on Building Structures, with a goal of achieving a well-conceived and technically sound design." />
            <div className="content-container">
                <Model path="models/office.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/office/0.jpg' },
                    { imagePath: 'images/office/1.jpg' },
                    { imagePath: 'images/office/2.jpg' },
                    { imagePath: 'images/office/3.jpg' },
                    { imagePath: 'images/office/4.jpg' },
                    { imagePath: 'images/office/5.jpg' },
                    { imagePath: 'images/office/6.jpg' },
                    { imagePath: 'images/office/7.jpg' },
                    { imagePath: 'images/office/8.jpg' },
                    { imagePath: 'images/office/9.jpg' },
                    { imagePath: 'images/office/10.jpg' },
                    { imagePath: 'images/office/11.jpg' },
                    { imagePath: 'images/office/12.jpg' },
                    { imagePath: 'images/office/13.jpg' },
                    { imagePath: 'images/office/14.jpg' },
                    { imagePath: 'images/office/15.jpg' },
                    { imagePath: 'images/office/16.jpg' },
                    { imagePath: 'images/office/17.jpg' },
                    { imagePath: 'images/office/18.jpg' },
                    { imagePath: 'images/office/19.jpg' },
                    { imagePath: 'images/office/20.jpg' },
                    { imagePath: 'images/office/21.jpg' },
                    { imagePath: 'images/office/22.jpg' },
                    { imagePath: 'images/office/23.jpg' },
                    { imagePath: 'images/office/24.jpg' },
                    { imagePath: 'images/office/25.jpg' },
                    { imagePath: 'images/office/26.jpg' },
                    { imagePath: 'images/office/27.jpg' },
                    { imagePath: 'images/office/28.jpg' },
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default Office;