import React from "react";
import Header from 'components/Header/header'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function MadisonCollegeGateway() {
    return (
        <>
            <Header pageName="Madison College Gateway" imagePaths={['images/revit.svg', 'images/blender.svg']} summary="During my internship at Assemblage Architects, I was responsible for creating a model of the existing exterior elevation for the Madison College Gateway." />
            <div className="content-container">
                {/* No model to add */}
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/madison-college-gateway/0.jpg' },
                    { imagePath: 'images/madison-college-gateway/1.jpg' },
                ]} />
            </div>
        </>
    );
}


export default MadisonCollegeGateway;