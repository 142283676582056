import React from "react";
import 'components/Spinner/spinner.css'

function Spinner() {
    return (
        // <img className="spinner" src="images/logo.svg" />
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    )
}

export default Spinner;