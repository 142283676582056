import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'


function NotFound() {
    return (
        <>
            <h1 style={{color: '#ffffff'}}>Not found :(</h1>
        </>
    );
}


export default NotFound;