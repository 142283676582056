import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function Home() {
    return (
        <>
            <Header />
            <div className="content-container">
                <Model path="models/madison-archive.glb" cameraPosition={{ x: 0, y: 5, z: 7 }} scale={1} />
                <Mosaic bricks={[
                    {
                        title: 'Architectural Studio',
                        imagePath: 'images/architectural-studio/0.jpg',
                        link: 'architectural-studio'
                    },
                    {
                        title: 'Artist Retreat',
                        imagePath: 'images/artist-retreat/0.jpg',
                        link: 'artist-retreat'
                    },
                    {
                        title: 'Barn',
                        imagePath: 'images/barn/0.jpg',
                        link: 'barn'
                    },
                    {
                        title: 'Bus Stop',
                        imagePath: 'images/bus-stop/0.jpg',
                        link: 'bus-stop'
                    },
                    {
                        title: 'Cabin Remodel',
                        imagePath: 'images/cabin-remodel/0.jpg',
                        link: 'cabin-remodel'
                    },
                    {
                        title: 'Design Center',
                        imagePath: 'images/design-center/0.jpg',
                        link: 'design-center'
                    },
                    {
                        title: 'Garage',
                        imagePath: 'images/garage/0.jpg',
                        link: 'garage'
                    },
                    {
                        title: 'Park',
                        imagePath: 'images/park/0.jpg',
                        link: 'park'
                    },
                    {
                        title: 'Madison Archive',
                        imagePath: 'images/madison-archive/0.jpg',
                        link: 'madison-archive'
                    },
                    {
                        title: 'Madison College Gateway',
                        imagePath: 'images/madison-college-gateway/0.jpg',
                        link: 'madison-college-gateway'
                    },
                    {
                        title: 'Office',
                        imagePath: 'images/office/0.jpg',
                        link: 'office'
                    },
                    {
                        title: 'Playground',
                        imagePath: 'images/playground/0.jpg',
                        link: 'playground'
                    },
                    {
                        title: 'Xbox Museum',
                        imagePath: 'images/xbox-museum/0.jpg',
                        link: 'xbox-museum'
                    }
                ]
                } />
            </div>
            <ScrollButton text="Scroll for projects" />
        </>
    );
}


export default Home;