import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function ArchitecturalStudio() {
    return (
        <>
            <Header pageName="Architectural Studio" imagePaths={['images/revit.svg', 'images/lumion.svg', 'images/blender.svg']} summary="This project is a testament to the significant time and effort I invested in its development, and I am proud of the successful outcome. At the outset of the project, I lacked any experience in utilizing Revit software. However, through consistent study and practice, particularly during my Software 2 course at Madison College, I was able to acquire and perfect the necessary skills. The project thus stands as a noteworthy demonstration of the knowledge and abilities I acquired during the course." />
            <div className="content-container">
                <Model path="models/architectural-studio.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/architectural-studio/0.jpg' },
                    { imagePath: 'images/architectural-studio/1.jpg' },
                    { imagePath: 'images/architectural-studio/2.jpg' },
                    { imagePath: 'images/architectural-studio/3.jpg' },
                    { imagePath: 'images/architectural-studio/4.jpg' },
                    { imagePath: 'images/architectural-studio/5.jpg' },
                    { imagePath: 'images/architectural-studio/6.jpg' },
                    { imagePath: 'images/architectural-studio/7.jpg' },
                    { imagePath: 'images/architectural-studio/8.jpg' },
                    { imagePath: 'images/architectural-studio/9.jpg' },
                    { imagePath: 'images/architectural-studio/10.jpg' },
                    { imagePath: 'images/architectural-studio/11.jpg' },
                    { imagePath: 'images/architectural-studio/12.jpg' },
                    { imagePath: 'images/architectural-studio/13.jpg' },
                    { imagePath: 'images/architectural-studio/14.jpg' },
                    { imagePath: 'images/architectural-studio/15.jpg' },
                    { imagePath: 'images/architectural-studio/16.jpg' },
                    { imagePath: 'images/architectural-studio/17.jpg' },
                    { imagePath: 'images/architectural-studio/18.jpg' },
                    { imagePath: 'images/architectural-studio/19.jpg' },
                    { imagePath: 'images/architectural-studio/20.jpg' },
                    { imagePath: 'images/architectural-studio/21.jpg' },
                    { imagePath: 'images/architectural-studio/22.jpg' },
                    { imagePath: 'images/architectural-studio/23.jpg' },
                    { imagePath: 'images/architectural-studio/24.jpg', }
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default ArchitecturalStudio;