import React, { useEffect, useRef, useState } from "react";
import 'components/Header/header.css'

function Header(props) {
    const [show, setShow] = useState(false);
    const containerRef = useRef(null);
    const buttonRef = useRef(null);


    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (containerRef.current.contains(document.activeElement)) {
                buttonRef.current.classList.add('header-button--open')
                setShow(true)
            } else {
                buttonRef.current.classList.remove('header-button--open')
                setShow(false)
            }
        })
    }, []);

    let logos = !props.imagePaths ? undefined : props.imagePaths.map(imagePath => {
        return <img className="header-details__logo" src={imagePath} alt="" key={imagePath} />
    })

    return (
        <header className="header">
            <nav>
                <a className="home-link">
                    <span className="home-link__name">Ben Buelow</span>
                    <span className="home-link__project">{props.pageName ? ` / ${props.pageName}` : ' | Portfolio'}</span>
                </a>
            </nav>

            <div className="header-container" ref={containerRef} tabIndex="0">
                <div className="header-button" ref={buttonRef}>{props.summary && props.pageName ? 'Summary' : 'Get in touch'}</div>

                {
                    props.summary &&
                    show &&
                    <div className="header-details header-details--summary">
                        <h2>{props.pageName}</h2>
                        <p>{props.summary}</p>
                        <div>
                            {logos}
                        </div>
                    </div>
                }
                {
                    !props.summary &&
                    show &&
                    <div className="header-details">
                        <img className="header-details__qr" src="images/contact-qr.png" alt="" />
                        <span>(920) 541-6131</span>
                        <span>ben1053549@gmail.com</span>
                    </div>
                }
            </div>
        </header>
    );
}

export default Header