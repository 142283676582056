import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function XboxMuseum() {
    return (
        <>
            <Header pageName="Xbox Museum" imagePaths={['images/revit.svg', 'images/blender.svg']} summary="This museum was created with the Xbox enthusiast in mind, featuring a unique building design modeled after the Xbox Series X and its accompanying controller. The project presented an enjoyable and engaging experience, and I was able to bring a high level of creativity and enthusiasm to the design process." />
            <div className="content-container">
                <Model path="models/xbox-museum.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/xbox-museum/0.jpg' },
                    { imagePath: 'images/xbox-museum/1.jpg' },
                    { imagePath: 'images/xbox-museum/2.jpg' },
                    { imagePath: 'images/xbox-museum/3.jpg' },
                    { imagePath: 'images/xbox-museum/4.jpg' },
                    { imagePath: 'images/xbox-museum/5.jpg' },
                    { imagePath: 'images/xbox-museum/6.jpg' },
                    { imagePath: 'images/xbox-museum/7.jpg' },
                    { imagePath: 'images/xbox-museum/8.jpg' },
                    { imagePath: 'images/xbox-museum/9.jpg' },
                    { imagePath: 'images/xbox-museum/10.jpg' },
                    { imagePath: 'images/xbox-museum/11.jpg' },
                    { imagePath: 'images/xbox-museum/12.jpg' },
                    { imagePath: 'images/xbox-museum/13.jpg' },
                    { imagePath: 'images/xbox-museum/14.jpg' },
                    { imagePath: 'images/xbox-museum/15.jpg' }
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default XboxMuseum;