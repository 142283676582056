import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function Playground() {
    return (
        <>
            <Header pageName="Playground" imagePaths={['images/sketchup.svg', 'images/lumion.svg', 'images/blender.svg']} summary="This playground project was completed as part of my Studio 1 course at Madison College. I utilized SketchUp as the primary design tool and later incorporated Lumion to produce an enhanced graphical representation. Designing the playground was an enjoyable and rewarding experience that provided an opportunity to express my creative abilities." />
            <div className="content-container">
                <Model path="models/old/playground.glb" cameraPosition={{ x: 0, y: 20, z: 60 }} scale={3    } />
                <Mosaic carousel={true} bricks={[
                    { imagePath: '/images/playground/0.jpg' },
                    { imagePath: '/images/playground/1.jpg' },
                    { imagePath: '/images/playground/2.jpg' },
                    { imagePath: '/images/playground/3.jpg' }
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default Playground;