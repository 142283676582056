import React, { useEffect, useState } from "react";
import 'components/ScrollButton/scrollButton.css'

export default function ScrollButton(props) {
    const [show, setShow] = useState(false);

    useEffect(() => {

        if (document.querySelector('html').scrollTop == 0) {
            setShow(true);
        }

        window.addEventListener('scroll', () => {
            setShow(false);
        });
    }, []);

    return (
        <>
            {
                show &&
                <button className="scroll-button" onClick={() => {
                    document.querySelector('.masonry').scrollIntoView();
                }}>
                    <span>{props.text}</span>
                    <img src="images/scroll.svg" alt="" />
                </button>
            }
        </>
    );
}