import React, { useEffect, useRef, useState } from "react";
import * as THREE from 'three'
import { Vector3 } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import 'components/Model/model.css'
import Spinner from 'components/Spinner/spinner'

React.lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve()
        }, 3000);
    });
});

function Model(props) {
    const canvasContainerRef = useRef(null);
    const canvasRef = useRef(null);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {

        const sizes = {
            width: canvasContainerRef.current.getBoundingClientRect().width,
            height: canvasContainerRef.current.getBoundingClientRect().height
        }

        const renderer = new THREE.WebGLRenderer({ antialias: true, canvas: canvasRef.current });
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.setSize(sizes.width, sizes.height);

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000);
        camera.position.set(props.cameraPosition.x, props.cameraPosition.y, props.cameraPosition.z)
        camera.lookAt(new Vector3(0, 0, 0))

        let theLogan
        const gltfLoader = new GLTFLoader()
        gltfLoader.load(
            props.path,
            (model) => {
                model.scene.scale.set(props.scale, props.scale, props.scale);
                theLogan = model.scene;
                scene.add(model.scene);

                setLoaded(true);
            },
            (progress) => {
                // console.log(progress)
            },
            (error) => {
                console.log('ERR', error);
            }
        );

        const light1 = new THREE.PointLight(0xffffff, 5, 100)
        light1.position.set(5, 20, 5)
        light1.lookAt(new Vector3(0, 0, 0))
        scene.add(light1)

        // const light2 = new THREE.PointLight(0xffffff, 5, 100)
        // light2.position.set(-5, 20, -5)
        // light1.lookAt(new Vector3(0, 0, 0))
        // scene.add(light2)

        const controls = new OrbitControls(camera, canvasRef.current);
        controls.autoRotate = true;
        controls.enableDamping = true;
        controls.enableZoom = false;

        const animate = () => {

            // Update controls
            controls.update()

            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        };
        animate();

        window.addEventListener('resize', () => {

            // Update sizes
            sizes.width = canvasContainerRef.current.getBoundingClientRect().width;
            sizes.height = canvasContainerRef.current.getBoundingClientRect().height;

            // Update renderer
            renderer.setSize(sizes.width, sizes.height);

            // Update camera
            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();

            // For move to different screen
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        });
    }, []);

    return (
        <div className="canvas-container" ref={canvasContainerRef}>
                <canvas ref={canvasRef} />
            {
                !loaded &&
                <Spinner />
            }

            {/* // Displays <Spinner> until OtherComponent loads */}
            {/* <React.Suspense fallback={<Spinner />}>
                <div>
                    <OtherComponent />
                </div>
            </React.Suspense> */}
        </div>
    );
}

export default Model;