import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function MadisonArchive() {
    return (
        <>
            <Header pageName="Madison Archive" imagePaths={['images/revit.svg', 'images/lumion.svg', 'images/blender.svg']} summary="In this project, I designed a habitable archive building with a focus on Project Goals, Mechanical Systems, and Structural Systems. I incorporated Energy Conservation, Equitable Communities, and Ecosystems as three of the ten architectural principles. The building features energy-efficient elements, welcoming seating areas, and green roofs. Mechanical systems include diffusers for optimal air distribution, a boiler, an air-cooled chiller, and air handling units. The building's framework is constructed with steel and concrete. Overall, I am pleased with the building's outcome, and I believe it effectively meets the goals and objectives of the project." />
            <div className="content-container">
                <Model path="models/madison-archive.glb" cameraPosition={{ x: 0, y: 10, z: 15 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/madison-archive/0.jpg' },
                    { imagePath: 'images/madison-archive/1.jpg' },
                    { imagePath: 'images/madison-archive/2.jpg' },
                    { imagePath: 'images/madison-archive/3.jpg' },
                    { imagePath: 'images/madison-archive/4.jpg' },
                    { imagePath: 'images/madison-archive/5.jpg' },
                    { imagePath: 'images/madison-archive/6.jpg' },
                    { imagePath: 'images/madison-archive/7.jpg' },
                    { imagePath: 'images/madison-archive/8.jpg' },
                    { imagePath: 'images/madison-archive/9.jpg' }
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default MadisonArchive;