import React, { useState } from "react";
import Frame from 'components/Frame/frame'
import './mosaic.css'
import Carousel from "components/Carousel/carousel";

function Mosaic(props) {
    const [carouselOpen, setCarouselOpen] = useState(false);

    const bricksJSX = props.bricks.map((brick, index) => {
        return (
            <a className="brick" href={brick.link} key={Math.random()} onClick={async () => {
                await setCarouselOpen(true)
                document.querySelector(`#image${index}`).scrollIntoView()
            }}>
                <Frame imagePath={brick.imagePath} />
                {
                    brick.title &&
                    <span>{brick.title}</span>
                }
            </a>
        );
    });

    const imagePaths = props.bricks.map(brick => {
        return brick.imagePath;
    });

    return (
        <>
            <div className="masonry" >
                {bricksJSX}
            </div>
            {
                carouselOpen &&
                props.carousel &&
                <Carousel imagePaths={imagePaths} setCarouselOpen={setCarouselOpen} />
            }
        </>
    );

}

export default Mosaic