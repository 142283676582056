import React from "react";
import Header from 'components/Header/header'
import Model from 'components/Model/model'
import Mosaic from 'components/Mosaic/mosaic'
import ScrollButton from 'components/ScrollButton/scrollButton'

function Park() {
    return (
        <>
            <Header pageName="Park" imagePaths={['images/sketchup.svg', 'images/lumion.svg', 'images/blender.svg']} summary="The purpose of this project was to fulfill the requirements of an eccentric collector who sought to open his collection to the public. My role was to select a suitable location within Dane County and develop a comprehensive site plan that encompassed 3-5 20' x 20' galleries, an experiential park, 3-5 outdoor sculpture exhibits, architectural viewing platforms, exploratory pathways, seating areas, canopies, raised planter beds, a water feature, and ample parking. The final design effectively addressed each of these objectives and stands as an outstanding accomplishment." />
            <div className="content-container">
                <Model path="models/park.glb" cameraPosition={{ x: 0, y: 20, z: 20 }} scale={1} />
                <Mosaic carousel={true} bricks={[
                    { imagePath: 'images/park/0.jpg' },
                    { imagePath: 'images/park/1.jpg' },
                    { imagePath: 'images/park/2.jpg' },
                    { imagePath: 'images/park/3.jpg' },
                    { imagePath: 'images/park/4.jpg' },
                    { imagePath: 'images/park/5.jpg' },
                ]} />
            </div>
            <ScrollButton text="Scroll for images" />
        </>
    );
}


export default Park;